// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #c22629;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

:root{
  --ion-color-theme: #c22629;
  --ion-color-theme-rgb: 255,73,97;
  --ion-color-theme-contrast: #ffffff;
  --ion-color-theme-contrast-rgb: 255,255,255;
  --ion-color-theme-shade: #ce2e31;
  --ion-color-theme-tint: #da3538;
}
.ion-color-theme {
  --ion-color-base: var(--ion-color-theme);
  --ion-color-base-rgb: var(--ion-color-theme-rgb);
  --ion-color-contrast: var(--ion-color-theme-contrast);
  --ion-color-contrast-rgb: var(--ion-color-theme-contrast-rgb);
  --ion-color-shade: var(--ion-color-theme-shade);
  --ion-color-tint: var(--ion-color-theme-tint);
}
// Global style
ion-toolbar{--background: var(--ion-color-theme); color: var(--ion-color-light); --min-height:60px!important;}
ion-toolbar ion-title{margin-left:-1rem}
ion-menu-button{color: var(--ion-color-light);}
.headerIcon{font-size: 28px;}
.headerIcon.search{font-size: 22px; margin-right: 1.5rem;}
.headerIcon.cart {position: relative; right: 1rem;}
.headerIcon.cart .cart-number{position: absolute; width: 25px; height:25px; border-radius: 50%; background-color: var(--ion-color-dark);font-size: 12px;
  text-align: center; padding-top: 6px; top:-7px; right: -10px;
  z-index: 9;overflow: hidden;}
  .main .companyTitle{box-shadow: none !important; border-radius: 0; height: auto;}
  // .main{margin:auto;}
  .title-div{position:sticky; top: 0;}
  // .item-block{width: 200px; margin: auto; box-shadow: 0 3px 6px var(--ion-color-dark-tint);}
  .main .item-block{width:200px;height: 265px; margin: 0.8rem; float: left; border-radius: 10px;box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.4); position: relative;}
  .main img{width: 100%; max-height: 300px; border-radius: 10px 10px 0 0;}
  .main ion-label{
  text-align: center;
  display: block;
  padding:10px 5px;
  color: var(--ion-color-dark);
}
.main .item-block.item_list ion-label{padding: 5px 0px 10px; position: absolute; height: auto;}
.main.grid-list img + ion-label,.main img + ion-label{
  position: inherit !important; 
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 48px;
bottom: 0; margin: auto; width: 94%; text-overflow: initial;white-space: initial;overflow: hidden; font-size: 0.95rem;
}
.main .item-block ion-label{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 48px;
   margin: auto; width: 94%; text-overflow: initial;white-space: initial;overflow: hidden; font-size: 0.95rem;
}
.companyTitle ion-card-title{font-size: 2.2rem;}
// ion-menu{width: 185px;}
.parallax-image {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: 50vh;
  border-radius: 10px;
}
ion-back-button{color:var(--ion-color-light);}
.main .item-block.item_list{position: relative;height: 320px;}
.main .item-block.item_list img{max-height: 190px;}
.main .item-block.item_list h2{position: absolute; left: 0; right: 0; top: 0; white-space: initial;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 38px;
bottom: 0; margin: auto; width: 94%; text-overflow: initial;white-space: initial;overflow: hidden; font-size: 0.95rem; }
.main img + ion-label h2{position: inherit !important;}
.main .item-block .cartbtn{width: 90%; margin: auto; text-align: center; position: absolute; left:0; right:0; bottom:0.4rem;}
.main .item-block.item_list p{margin:0.5rem 0;}
.item-block ion-label ion-icon{position: relative; top: 2px;}
.Item_price{display: flex; justify-content: space-between; align-items: center;}
.itemCartTotal{position: fixed; left:0; width: 100%; bottom: 0; z-index: 99; padding:0.4rem 2.2rem 0.4rem 1.3rem; background-color: var(--ion-color-light);}
.vertical_data{display: flex; align-items: center; justify-content: space-between;width:100%;}
.vertical_data.ios{align-items: center;}
.vertical_data ion-list{width: 100%; padding:0;}
.vertical_data ion-checkbox{margin:0; width:20px;}
.main .vertical_data ion-label{position: initial !important;}
.ion-text-ellips{text-overflow: ellipsis !important; white-space:nowrap; width: 80%; overflow: hidden !important;}
.cart_item_data{display: flex; align-items: center; justify-content: space-between;}

.margin-bottom{margin-bottom: 5rem; text-align:center;}
.amount-detils{text-align: right; padding: 0.8rem; margin-bottom: 4.2rem; box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.2); }
.amount-detils label{text-align:left; display: block; font-size: 1rem;}
.total-label{text-align: left;font-size:1.5rem !important; display: block;}
.dotted-border{padding-bottom: 0.3rem; margin-bottom: 0.3rem;}
.solid-border{border-top: 1px dotted #999999; padding-top: 0.4rem; margin-top: 0.7rem;}
.grid-list .item-row{display: flex;display:-ms-flexbox; flex-wrap: wrap; margin:0;}
.grid-list .item-col{flex:0 0 20%; -ms-flex:0 0 20%; max-width: 20%; padding: 0;}
.cart-thumb{height: 80px; width: 80px;}
.cart-tip-icon{height: 50px; width: 50px;}
.empty-cart{text-align: center; padding:15% 0;}
.empty-cart ion-icon{font-size: 6rem; color: var(--ion-color-dark-tint); opacity: 0.5;}
.total-price{margin-right:2rem; font-size: 2rem;}
.cart-total-price{font-size: 2rem;}
.alert-button.sc-ion-alert-md,.alert-radio-label, .alert-button.sc-ion-alert-ios{color: var(--ion-color-theme);}
.alert-radio-button .alert-radio-icon[type=radio]:checked{border-color: var(--ion-color-theme) !important;}
.skip-btn{background: #000000;
  text-decoration: none;
  padding: 4px 14px;
  display: inline-block;
  border-radius: 20px;
  font-size: 13px;
  color: #ffffff;
  margin-top: 10px;
  // animation: bubbleskip 1s infinite;
}
// @keyframes bubbleskip {
//   0%{transform: scale(1,1);}
//   100%{transform: scale(1.1, 1.2);}
// }

.remember-check ion-checkbox{margin:0 1rem 0 0;}
@media screen and (min-width:1200px) and (max-width:5000px){
  .homePage .parallax-image{background-size: 500px !important; height: 500px !important; width: 500px !important; margin: auto;}
}
@media screen and (min-width:1600px) and (max-width:3000px){
  .parallax-image{height: 50vh;}
  .total-price, .cart-total-price{font-size:2.4rem;}
}

@media screen and (max-width:1366px){
  .parallax-image{height: 43vh;}
}
@media screen and (max-width:1170px){
  .main .item-block{
    width: 220px;
    height: 280px;
  }
  .grid-list .item-col{flex:0 0 33.33%; -ms-flex:0 0 33.33%; max-width: 33.33%; padding: 0;}
  .cart-thumb{height:60px; width:60px;}
  .total-price{margin-right:1rem;}
  .parallax-image{height: 50vh;}
}
@media screen and (max-width:990px){
  ion-menu{width: auto;}
  .main .item-block{
    width: 225px;
    height: 275px;
  }
  .parallax-image {
    background-repeat: no-repeat;
    background-position: center -100px;
    background-size: 100%;
    background-attachment: fixed;
    height: 50vh;
  }
  .main .item-block{height: 290px; border-radius: 10px;box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.4); position: relative;}
  .main img{width: 100%; max-height: 300px;}
  .main .item-block.item_list{height: 320px;}
  .main .item-block.item_list img{height: 300px;}
  .headerIcon.cart .cart-number{width: 23px; height: 23px; padding-top: 5px; top: -5px; font-size: 11px;}
  .title-div{position:initial;}
  .parallax-image{height: 65vh; background-position: center -95px; background-attachment: initial; z-index:-1; position: fixed; top:2rem; left:0; width: 100vw;}
  .mobile-list{background-color: #ffffff; margin-top: 2rem;;}
  .main .companyTitle{margin-top: 70%; padding: 0.6rem 0;;
    background-color: #ffffff;
    z-index: 9;
    position: relative;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -2.4rem;}
    .details-card{margin-top: 60%;}
    // .details-card{margin: 20rem 0 0;}
    .mobile-card-details{position: relative; top:1rem; margin-bottom: 2rem;}
    .mobile-card{
      margin: 0 -1rem -4rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 3rem;
      background: #fff;
    }
    .total-price,.cart-total-price{font-size:1.5rem;}
    .vertical_data ion-checkbox.ios{width:25px;}
    .vertical_data.align-top{align-items:start;}
    .vertical_data.align-top ion-checkbox{margin-top: 8px;}
}

@media screen and (max-width:767px) {
  .item-row{display: flex;display:-ms-flexbox; flex-wrap: wrap; margin:0;}
  .item-col{flex:0 0 50%; -ms-flex:0 0 50%; max-width: 50%; padding: 0;}
  .main .item-block{height: 255px; float: none; margin: 0.5rem auto !important;}
  .companyTitle ion-card-title{font-size: 1.75rem;}
  .main img{width: 100%; max-height: 190px;}
  .grid-list .item-col {
      flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      max-width: 50%;
      padding: 0;
  }
  .title-div{position:initial;}
  .parallax-image{height: 65vh; background-position: center -95px; background-attachment: initial; z-index:-1; position: fixed; top:2rem; left:0; width: 100vw;}
  .mobile-list{background-color: #ffffff; margin-top: 2rem;}
  .col-mobile-list{margin-top: 0; padding-top: 2rem;}
  .main .companyTitle{margin-top: 20rem; padding: 0.6rem 0;
    background-color: #ffffff;
    z-index: 9;
    position: relative;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -2.4rem;}
  .details-card{margin-top: 20rem;}
  .mobile-card-details{position: relative;}
  .mobile-card{
    margin: 0 -1rem -2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    background: #fff;
  }
  .total-price, .cart-total-price{font-size:1.2rem;}
  .main.grid-list img + ion-label, .main img + ion-label{
    white-space: initial;
    -webkit-line-clamp:3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 55px;
    padding:0 10px;
  }
  .main .item-block ion-label{
    -webkit-line-clamp: 3; height: 54px; padding: 0 10px; width: 98%;
  }
  .main .item-block.item_list{height: 320px;}
  .main .item-block.item_list h2{    
    white-space: initial;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 53px;
    top:0;
    overflow: hidden;
  }
  .main .vertical_data ion-label{font-size:14px;
    white-space: initial;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 45px;
  }
  .dotted-border{padding-bottom: 0.1rem; margin-bottom: 0.1rem;}
  .solid-border{margin-top: 0.3rem;}
  .amount-detils{margin-bottom:4.3rem;}
  // .amount-detils label{font-size: 0.8rem;}
  .total-label, .cart-total-price{font-size: 1.3rem; }
  .total-label{font-size: 1.3rem !important;}
  .itemCartTotal{padding: 0.4rem 1.3rem 0.4rem 1.3rem;}
}
@media screen and (max-width:480px) {
  .main .item-block{
    width: 170px;
    height: 240px;
    margin: 0.4rem;
  }
  .main .item-block .cartbtn{bottom:0.5rem;}
  .parallax-image{height: 50vh; background-position: top;}
  .main ion-label{font-size: 1.1rem;text-align: center;}
  .main .item-block.item_list{height:300px;}
  .main .item-block.item_list img{max-height: 150px;}
  .main .item-block.item_list h2{font-size: 0.9rem; top:0; font-weight: bold; text-align:center;}
  .main .item-block.item_list p{font-size: 1.05rem; font-weight: normal;}
  .main .item-block.item_list ion-label{text-align: left;}
  .vertical_data ion-checkbox.ios{width:32px; margin-left: -4px;}
  .main .vertical_data ion-label{
    font-size:14px;
    white-space: initial;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 45px;
  }  
}
@media screen and (max-width:360px) {
  .main .item-block{
    width: 160px;
    height: 210px;
  }
  .vertical_data ion-checkbox.ios{width:33px; margin-left: -5px;}
  .vertical_data ion-checkbox{width: 22px;}
  
}
@media screen and (max-width:320px) {
  .main .item-block{
    width: 140px;
    height: 190px;
    margin: 0.4rem;
  }
  .main ion-label{font-size: 1rem;}
  .vertical_data ion-checkbox.ios{width:36px; margin-left: -5px;}
  
}

.loading-spinner {
  --spinner-color: var(--ion-color-theme);
}

.language-popup-custom-class {

  .alert-radio-icon.sc-ion-alert-md {
    border-color: var(--ion-color-theme);
  }
  [aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
    border-color: var(--ion-color-theme);
  }

  .alert-radio-icon.sc-ion-alert-ios {
    border-color: var(--ion-color-theme);
  }
  [aria-checked=true].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
    border-color: var(--ion-color-theme);
  }

  .alert-radio-inner.sc-ion-alert-md{
    background-color: var(--ion-color-theme);
  }

  [aria-checked=true].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
    color: var(--ion-color-theme);
  }

}

.comment-label ion-icon{float: left; margin-right: 10px; margin-top: 2px;}
.comment-label .cart-option-title{white-space: normal;}
.tag-badge { margin-right: 5px; opacity: 0.7;}
.tag-badge-category-block { position: absolute; z-index: 1; top:0;}
.auth-img { border-radius: 10px;}
.item-block{  cursor: pointer; }
.pointer{  cursor: pointer; }
.thumb-box{
  box-sizing: border-box; 
  display: block; 
  overflow: hidden; 
  width: initial; 
  height: initial; 
  background: none; 
  opacity: 1; 
  border: 0px; 
  margin: 0px; 
  padding: 0px; 
  position: absolute; 
  inset: 0px;
}
.thumb-box img{
  position: absolute; 
  inset: 0px; 
  box-sizing: border-box; 
  padding: 0px; 
  border: none; 
  margin: auto; 
  display: block; 
  width: 0px; 
  height: 0px; 
  min-width: 100%; 
  max-width: 100%; 
  min-height: 100%; 
  max-height: 100%; 
  object-fit: cover;
}
.hover\:bg-interactive-subtle-surface-hover:hover{
  background-color: #f8f8f8;
}

.home-btn { font-size: 25px;}
.ios{
  .home-btn { margin-left: 10px; }
}
.item-block-tw-effect {
  @apply ease-in duration-200 hover:scale-105;  
}
.leftMenu .menuList {@apply cursor-pointer;}
.main-title {box-shadow: none !important; border-radius: 0; height: auto;}